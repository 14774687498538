<script setup lang="ts">
import type { NotificationDto } from "~~/_api_generated_";

const INVITED = "invited";
const UNINVITED_ELIGIBLE = "UNINVITED_ELIGIBLE";
const UNINVITED_INELIGIBLE = "UNINVITED_INELIGIBLE";

const modalVisible = ref(false);
const modalType = ref<string | null>(null);
const modalParams = ref<NotificationDto["callToActionParam"]>();

const store = useDefaultStore();
const ftuNotification = computed(() => store.ftuNotification);

watch(
  ftuNotification,
  (value) => {
    if (value?.id) {
      const { hasEligibleCampaign, hasEligiblePosts } =
        value?.callToActionParam ?? {};

      let selectedModalType = null;

      if (hasEligibleCampaign && hasEligiblePosts) {
        selectedModalType = INVITED;
      } else if (hasEligibleCampaign && !hasEligiblePosts) {
        selectedModalType = UNINVITED_ELIGIBLE;
      } else {
        selectedModalType = UNINVITED_INELIGIBLE;
      }

      modalParams.value = value?.callToActionParam;
      modalType.value = selectedModalType;
      modalVisible.value = true;

      setNotificationAsSeen(value.id);
    }
  },
  {
    immediate: true,
  },
);

function setNotificationAsSeen(notificationId: string) {
  apiService.markAsSeen(notificationId).then(() => {
    store.refreshNotifications();
  });
}
</script>

<template>
  <Modal
    v-if="modalVisible"
    class="modal--content"
    @close="modalVisible = false"
  >
    <LazyModalsWelcomeModal
      v-if="modalType == INVITED && modalParams"
      :metadata="modalParams"
      @close="modalVisible = false"
    />
    <LazyModalsWelcomeEligibleUserModal
      v-else-if="modalType == UNINVITED_ELIGIBLE && modalParams"
      :metadata="modalParams"
      @close="modalVisible = false"
    />
    <LazyModalsWelcomeStrangerModal
      v-else-if="modalType == UNINVITED_INELIGIBLE && modalParams"
      :metadata="modalParams"
      @close="modalVisible = false"
    />
  </Modal>
</template>
