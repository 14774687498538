<script setup lang="ts">
import SigninButton from "~/components/SigninButton.vue";

const learnMoreModalVisible = ref(false);

onMounted(() => {
  trackEvent("landing_page_viewed", {
    brand: "evangelist",
    invited: false,
  });

  trackEvent("evangelist_landing_page_viewed");

  initGateway();
});

useHead({
  bodyAttrs: {
    class: "body--evangelist-confetti",
  },
});

definePageMeta({
  layout: "blank",
});
</script>

<template>
  <Modal
    v-if="learnMoreModalVisible"
    class="modal--content"
    @close="learnMoreModalVisible = false"
  >
    <ContentsHowDoesItWorksSlider />
    <div class="text-center mt-8">
      <button
        class="btn bg-purple-light hover:bg-black hover:text-white"
        @click="learnMoreModalVisible = false"
      >
        Done
      </button>
    </div>
  </Modal>

  <header class="py-3 pt-6 header mt-0 lg:mt-6">
    <div
      class="container-1200 flex gap-4 justify-between items-start lg:items-center"
    >
      <div class="w-16 hidden lg:block">&nbsp;</div>
      <div class="flex gap-4 items-center">
        <div class="text-lg lg:text-2xl">Introducing</div>
        <img class="h-6 lg:h-8" src="/logos/logo-evangelist.svg" />
      </div>
      <div class="w-16 text-right">
        <SigninButton class="text-purple hover:underline">
          Log In
        </SigninButton>
      </div>
    </div>
  </header>
  <div class="container-1200 mt-8 lg:mt-20 lg:text-center font-semibold">
    <div
      class="text-3xl lg:text-6xl mb-6 lg:mb-14 lg:mx-auto"
      style="max-width: 700px"
    >
      Just post stuff about the things
      <span class="text-purple">you love</span>
      <span>.</span>
    </div>
    <div class="text-2xl lg:text-2xl mb-0 w-2/3 lg:w-full">
      And get paid while doing that.
    </div>
    <div class="my-8 lg:my-14">
      <SigninButton
        class="btn bg-purple-light text-black hover:text-white hover:bg-black"
        >Check eligibility via
        <IconsTweetIcon />
      </SigninButton>
    </div>
    <div class="text-xl lg:text-2xl mb-8 mt-0 lg:mt-20 pr-8 lg:pr-0 lg:w-full">
      <span
        >Evangelist helps ambassadors amplify product messages they believe
        in,</span
      >
      <span class="inline lg:block">
        using AI and blockchain for
        <span class="text-purple">fair compensation</span>
        and
        <span class="text-purple">greater impact</span>.
      </span>
    </div>
  </div>

  <div class="container-1200">
    <div class="border-t-2 border-gray-300 my-10 lg:my-28"></div>
    <div
      class="flex flex-wrap justify-start lg:justify-center items-center gap-6 lg:gap-12"
    >
      <div class="text-gray-500 text-lg lg:text-2xl w-full lg:w-auto">
        Pilot campaign partners
      </div>
      <img src="/logos/logo-marinade.svg" class="w-1/3 lg:w-auto" />
      <img src="/logos/logo-deriverse.svg" class="w-1/3 lg:w-auto" />
    </div>
  </div>

  <div class="container-1200 mt-14">
    <div class="p-6 py-8 lg:p-12 bg-white rounded-2xl">
      <div class="relative mx-auto" style="max-width: 600px">
        <ContentsHowDoesItWorksSlider>
          <template #top-part>
            <div class="text-black mb-2 lg:mb-8">
              How do Ambassadors get paid?
            </div>
          </template>
        </ContentsHowDoesItWorksSlider>
      </div>
    </div>
  </div>

  <div class="my-8 lg:mb-14 lg:mt-20">
    <div
      class="container-1200 flex flex-wrap justify-between items-center gap-8"
    >
      <div class="font-semibold items-center gap-2 text-xl pr-20 lg:pr-0">
        Interested in partnering with
        <span class="px-1 inline-block">
          <IconsEvangelistIcon class="inline w-5 h-5" />
        </span>
        Evangelist?
      </div>
      <div>
        <a
          href="mailto:founders@evangelist.gg"
          class="btn bg-purple-light text-black hover:bg-black hover:text-white px-6"
          >Contact Us</a
        >
      </div>
    </div>
  </div>

  <footer class="pb-10 lg:pb-28">
    <div class="container-1200">
      <div class="border-t-2 border-gray-300 mt-6 lg:mt-28 mb-6 lg:mb-16"></div>
      <div class="flex justify-between items-center gap-8">
        <div class="flex gap-2 items-center">
          <IconsEvangelistIcon />
          <div class="hidden lg:block">2024 Evangelist</div>
        </div>
        <div class="flex flex-wrap gap-4 lg:gap-8 text-xs lg:text-base">
          <NuxtLink class="hover:underline" to="/articles/privacy-policy"
            >Privacy Policy</NuxtLink
          >
          <NuxtLink class="hover:underline" to="/articles/terms"
            >Terms and Conditions</NuxtLink
          >
        </div>
      </div>
    </div>
  </footer>
</template>

