<script setup lang="ts">
const transactionId = ref<string | null>(null);

function onAfterVerify() {
  transactionId.value = null;
}

function setTransactionId(id: string) {
  transactionId.value = id;
}

onMounted(() => {
  globalOn("transaction::showOtpModal", setTransactionId);
});

onBeforeUnmount(() => {
  globalOff("transaction::showOtpModal", setTransactionId);
});
</script>

<template>
  <Modal v-if="transactionId" @close="transactionId = null">
    <WithdrawalConfirmOtp
      :transaction-id="transactionId"
      @refresh="onAfterVerify"
    />
  </Modal>
</template>
