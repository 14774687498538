<script setup lang="ts">
const defaultStore = useDefaultStore()

const paychecks = computed(() => {
    return defaultStore?.paychecks ?? [];
});

const paycheck = computed(() => {
    return paychecks.value?.[0] ?? null;
});

</script>

<template>
    <PaycheckModal v-if="paycheck" :paycheck="paycheck"/>
</template>