import type { LeaderboardEntryDto } from "~~/_api_generated_";

export function addMemberStyles(
  members: LeaderboardEntryDto[] = [],
  layout = "default",
) {
  return members.map((member: LeaderboardEntryDto, index) => {
    let rowClass = "";

    if (member?.isLoggedUser) {
      rowClass = "bg-black text-white";
    } else if (index == 0) {
      rowClass = "bg-purple-light";

      if (layout == "paycheck") {
        rowClass = "bg-white text-black";
      }
    } else if (index == 1) {
      rowClass = "bg-grey-light";

      if (layout == "paycheck") {
        rowClass = "bg-black/20 text-black";
      }
    }

    return {
      ...member,
      rowClass,
    };
  });
}
