<script setup lang="ts">

</script>

<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_2337_8397)">
            <path d="M12 19C12 15.13 15.13 12 19 12C20.08 12 21.09 12.25 22 12.68V6C22 4.9 21.1 4 20 4H4C2.9 4 2 4.9 2 6V18C2 19.1 2.9 20 4 20H12.08C12.03 19.67 12 19.34 12 19ZM4 6L12 11L20 6V8L12 13L4 8V6ZM17.34 22L13.8 18.46L15.21 17.05L17.33 19.17L21.57 14.93L23 16.34L17.34 22Z" style="fill:currentColor"/>
        </g>
        <defs>
            <clipPath id="clip0_2337_8397">
                <rect width="24" height="24" fill="white"/>
            </clipPath>
        </defs>
    </svg>

</template>

<style scoped>

</style>