<script setup lang="ts">
import LeaderBoardSIdebar from "~/components/Campaign/LeaderBoardSIdebar.vue";
import MasonryWall from "@yeger/vue-masonry-wall";
import type { PayoutDto } from "~~/_api_generated_/api";

interface Props {
  paycheck: PayoutDto;
}
const props = defineProps<Props>();

const paycheckPosts = computed(() => {
  return props.paycheck.posts;
});

const campaign = computed(() => {
  return props.paycheck.campaign;
});

const reward = computed(() => {
  return props.paycheck.reward;
});

const store = useDefaultStore();
const rewarding = ref(false);
const active = ref(true);
const sidebarVisible = ref(false);

const computedMembers = computed(() => {
  return addMemberStyles(props.paycheck.leaderboard.entries ?? [], "paycheck");
});

function claimAllRewards() {
  rewarding.value = true;

  apiService
    .claimAllRewards(campaign.value.id)
    .then(() => {
      successMessage("Rewards claimed successfully");
      store.paychecks = store.paychecks.filter(
        // @ts-expect-error TODO: ckhec paycheck id with BE
        (p) => p.id !== props.paycheck.id,
      );
      store.loadPaychecks();

      trackEvent("rewards_claimed", {
        campaign_id: campaign.value.id,
        campaign_title: campaign.value.title,
      });

      useRouter().push("/");
      globalEmit("refreshBalance");
    })
    .catch((error) => {
      errorMessage(error);
    })
    .finally(() => {
      rewarding.value = false;
    });
}

onMounted(() => {
  trackEvent("payday_modal_active", {
    campaign_id: campaign.value.id,
    campaign_title: campaign.value.title,
  });
});
</script>

<template>
  <Modal v-if="active" class="modal--1024" @close="active = false">
    <Confetti />

    <div class="text-center">
      <div class="text-3xl lg:text-4xl font-bold mt-10">It's Payday!</div>
      <div class="my-4 text-lg mt-8">
        Thank you for participating in this campaign.<br
          class="hidden lg:block"
        />
        We are now rewarding your
        <span class="text-purple"
          >top {{ (paycheck?.posts ?? []).length }} posts</span
        >
        this week.
      </div>
    </div>

    <div class="grid grid-cols-1 lg:grid-cols-3 my-10 gap-6 lg:gap-4">
      <div
        class="lg:col-span-2 p-6 bg-gradient-to-b from-white/30 to-gray-200 border border-gray-300 rounded-2xl"
      >
        <div class="flex lg:items-center justify-between gap-6">
          <div>
            <div class="leading-tight lg:leading-snug text-lg font-semibold">
              {{ campaign.title }}
            </div>
          </div>
          <div class="hidden lg:block">
            <BrandsIconAndName
              class="font-semibold"
              :theme="campaign?.advertiser?.theme"
            />
          </div>
          <div class="block lg:hidden w-8 shrink-0">
            <BrandsIcon
              class="font-semibold"
              :theme="campaign?.advertiser?.theme"
            />
          </div>
        </div>
        <div
          class="mt-4 lg:mt-7 grid grid-cols-1 lg:grid-cols-2 gap-3 lg:gap-6 rounded-2xl font-semibold cursor-pointer"
        >
          <div class="">
            <div class="flex gap-2 items-end my-4 text-5xl font-bold">
              {{ transformToPercentage(reward?.averagePostScore ?? "0") }}
            </div>
            <div class="text-black/50">Avg. Post Relevance</div>
          </div>
          <div class="">
            <div class="flex gap-2 items-end my-4 text-5xl font-bold">
              {{ formatNumberUSA(reward?.totalRewards?.amount ?? "0") }}
              <span class="text-2xl uppercase">{{
                reward?.totalRewards?.currency ?? ""
              }}</span>
            </div>
            <div class="text-black/50">
              Your Total Rewards (~{{
                formatCompactCurrency(reward?.totalRewards?.estimatedValueUsd)
              }})
            </div>
          </div>
        </div>
      </div>
      <div class="bg-purple-light p-6 rounded-2xl">
        <div class="flex items-center mb-5 justify-between">
          <div class="text-black/50">Leaderboard</div>
          <div
            class="hover:underline cursor-pointer"
            @click="sidebarVisible = true"
          >
            See all
          </div>
        </div>
        <CampaignLeaderboard
          :members="paycheck?.leaderboard?.entries"
          :creator-number="3"
          :campaign-name="paycheck?.campaign?.title"
          :show-title="false"
          :show-footer="false"
          :show-shares="false"
          layout="paycheck"
        />
      </div>
    </div>

    <div class="my-10 text-center">
      <button
        class="btn bg-purple-light hover:bg-black hover:text-white"
        :disabled="rewarding"
        @click="claimAllRewards"
      >
        <LoadingIcon v-if="rewarding" class="icon" />
        <IconsWalletIcon v-else />
        Claim All Rewards
      </button>
    </div>

    <div>
      <MasonryWall
        :items="paycheckPosts"
        :ssr-columns="1"
        :column-width="300"
        :gap="16"
      >
        <template #default="{ item }">
          <TweetPaycheckTweet :post="item" />
        </template>
      </MasonryWall>
    </div>
  </Modal>

  <Sidebar v-if="sidebarVisible" @close="sidebarVisible = false">
    <LeaderBoardSIdebar
      :members="computedMembers"
      :campaign-name="paycheck?.campaign?.title"
      layout="paycheck"
    />
  </Sidebar>
</template>
